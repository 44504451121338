import React, { useEffect, useState } from "react";
import axios from "axios";
import PageButton from "../PageButton";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { PageProps } from "./PageOne";
import { BookingInfo } from "./BookingForm";
import {
  Box,
  CircularProgress,
  Slide,
  SlideProps,
  Stack,
  Typography,
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneIcon from "@mui/icons-material/Phone";
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import { useLanguageContext } from "../LanguageContextProvider";
import { domainURL } from "../Variables/Domain";

interface Props {
  info: BookingInfo;
  returnToPageOne: () => void;
}

type TransitionProps = Omit<SlideProps, "direction">;
function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

function PageFour({ info, returnToPageOne }: Props) {
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(6);
  const [bookingId, setBookingId] = useState<number | undefined>(undefined);
  const lang = useLanguageContext();

  
  useEffect(() => {
    //console.log(info)
    let info_api = { ...info } as any;
    info_api.dateTime = info.date?.format("YYYY-MM-DD HH:mm:ss");
    info_api.day = info.date?.format("dddd");
    info_api.date = info.date?.format("MMM D");
    info_api.time = info.date?.format("h:mmA");
    info_api.lang = lang;

    axios
      .post(`${domainURL}/api/client/`, {
        data: info_api,
        postType: "client_saveNewBooking",
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        if (response.data.status === "success") {
          setBookingId(parseInt(response.data.booking_id))
          setSuccess(true);
          gtag_report_conversion()
        }else {
          setError(response.data.status)
          setSuccess(false);
          const timeoutId = setTimeout(() => {
            returnToPageOne();
          }, 6000);

          return () => {
            clearTimeout(timeoutId); // This clears the timeout if the component unmounts before the delay is reached
          };
        }
      });
  }, []);


  
  const gtag_report_conversion = () => {
    let time = info.date ? info.date?.format("HH") : "00"
    console.log(time)
    let guestNum = info.guestNum ?? 0
    let value = 0
    if(parseInt(time) < 15){
      console.log(time)
      value = 150 * guestNum
    }
    else{
      console.log(time)
      value = 300 * guestNum
    }
    console.log(value)

    gtag('event', 'conversion', {
        'send_to': 'AW-10836524742/10pKCJrbke8YEMb9oK8o',
        'value': value,
        'currency': 'HKD',
    });
    //gtag('set', 'user_data', {
    //  'email': info.personalInfo.email
    //})
    return false;
  }


  useEffect(() => {
    count > 0 && setTimeout(() => setCount(count - 1), 1000);
  }, [count])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "stretch",
        alignItems: "stretch",
        minHeight: "200px",
      }}
    >
      {loading && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {success === true ? (
        <Stack sx={{ flex: 1, textAlign: "left" }}>
          <Box
            sx={{
              width: "100%",
              fontSize: "20px",
              mb: "20px",
              color: "text.secondary",
            }}
            data-cy={"success"}
          >
            {lang === "tc" && "預訂成功"}
            {lang === "en" && "Successfully Booked"}
          </Box>
          <Box
            sx={{
              width: "100%",
              fontSize: "12px",
              mb: "20px",
              color: "text.secondary",
            }}
          >
            {lang === "tc" && "確認電郵已發送，如未能找到相關確認電郵，請查看垃圾郵件，或與我們聯絡以作確認。"}
            {lang === "en" && "Confirmation email has been sent. If you have not received it, please check your spam folder, or contact us directly."}
          </Box>
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "#fff7f7",
              p: "20px",
              "> div": { display: "flex", alignItems: "center"},
            }}
          >
             <Box>
              <Grid3x3Icon fontSize="small" color="action" sx={{ mr: "10px" }} />
              {bookingId}
            </Box>
            <Box>
              <PersonIcon fontSize="small" color="action" sx={{ mr: "10px" }} />
              {info.guestNum}
            </Box>
            <Box>
              <CalendarMonthIcon fontSize="small" color="action" sx={{ mr: "10px" }} />
              {info.date?.format("YYYY-MM-DD")}
            </Box>
            <Box>
              <ScheduleIcon fontSize="small" color="action" sx={{ mr: "10px" }} />
              {info.date?.format("HH:mm")}
            </Box>
            <Box>
              <PhoneIcon fontSize="small" color="action" sx={{ mr: "10px" }} />
              {info.personalInfo.mobile}
            </Box>
          </Stack>
        </Stack>
      ) : success === false ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
          }}
          data-cy={"fail"}
        >
          <Typography sx={{fontSize: "16px"}}>
            {lang === "tc" && error === "full" && "很抱歉，所選時段已被訂滿，請再嘗試，此頁會於"}
            {lang === "tc" && error !== "full" && "網絡連線問題，請再嘗試，此頁會於"}
            {lang === "en" && error === "full" && <span>Selected timeslot has been taken. <br/> Please try again. This page will be <br/>refreshed in </span>}
            {lang === "en" && error !== "full" && <span>Network connection problem. Please<br/>try again. This page will be refreshed in </span>}

            <span style={{color: "red"}}>{count}</span>

            {lang === "tc" && "秒內更新。"}
            {lang === "en" && " seconds."}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

export default PageFour;
