import React, { useEffect, useRef, useState } from "react";
import { useLanguageContext } from "../LanguageContextProvider";
import { redirect, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RatingWithLabel, { Category } from "./RatingWithLabel";
import { domainURL } from "../Variables/Domain";

interface Ratings {
  food: number;
  service: number;
  ambience: number;
  cleanliness: number;
}

const FeedbackForm = () => {
  const lang = useLanguageContext();
  const location = useLocation();

  const [rating, setRating] = useState<Ratings>({
    food: 0,
    service: 0,
    ambience: 0,
    cleanliness: 0,
  });

  const [error, setError] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean | null>(null);

  const commentRef = useRef<HTMLInputElement>();

  const handleOnChangeRating = (
    category: Category,
    newValue: number | null
  ) => {
    if (newValue) {
      let rating_cloned = { ...rating };
      rating_cloned[category] = newValue;
      setRating(rating_cloned);
    }
  };

  const handleOnClicked = () => {
    //setSuccess(false)
    //return
    if (
      rating.food === 0 ||
      rating.cleanliness === 0 ||
      rating.service === 0 ||
      rating.ambience === 0
    ) {
      setError(true);
      return;
    }
    setError(false);

    if (commentRef.current) {
      //console.log(commentRef.current?.value);
      axios
        .post(`${domainURL}/api/client/feedback/`, {
          rating: rating,
          comment: commentRef.current?.value,
          search: location.search.substring(1),
        })
        .then((response) => {
          //console.log(response);
          if (response.status === 200 && response.data.status === "success") {
            setSuccess(true);
            const timeoutId = setTimeout(() => {
              window.location.href = "https://www.ivanthekozak.com.hk";
            }, 1000);

            return () => {
              clearTimeout(timeoutId); // This clears the timeout if the component unmounts before the delay is reached
            };
          } else {
            setSuccess(false);
          }
        });
    }
  };

  return (
    <>
      {lang === "tc" ? (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "#000A66", m: 2 }}
        >
          非常感謝您的光臨
          <br />
          請留下您的評價
        </Typography>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            color: "#000A66",
            fontWeight: 900,
            fontSize: "20px",
            wordBreak: "break-word",
            mb: 2
          }}
        >
          Thank you for your visit
          <br />
          Your feedback is valuable to us
        </Typography>
      )}

      <Stack
        sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
      >
        <Stack spacing={1} sx={{ flex: { md: 1 }, mx: { md: 1 } }}>
          <RatingWithLabel
            label={lang === "tc" ? "食物" : "Food"}
            category={"food"}
            onChangeRating={handleOnChangeRating}
          />
          <RatingWithLabel
            label={lang === "tc" ? "服務" : "Service"}
            category={"service"}
            onChangeRating={handleOnChangeRating}
          />
          <RatingWithLabel
            label={lang === "tc" ? "氛圍" : "Ambience"}
            category={"ambience"}
            onChangeRating={handleOnChangeRating}
          />
          <RatingWithLabel
            label={lang === "tc" ? "衛生" : "Cleanliness"}
            category={"cleanliness"}
            onChangeRating={handleOnChangeRating}
          />
        </Stack>

        <Stack sx={{ flex: { md: 1 }, mx: { md: 1 } }}>
          <Typography
            sx={{
              mt: { xs: 5, md: 0 },
              mb: 2,
              fontWeight: 900,
              color: "#000A66",
            }}
          >
            {lang === "tc" ? "意見" : "Review"}
          </Typography>
          <TextField
            color="secondary"
            sx={{ bgcolor: "#eaeefa" }}
            inputProps={{ style: { fontSize: "14px", wordBreak: "break-word", } }}
            inputRef={commentRef}
            multiline
            rows={5}
            placeholder={
              lang === "tc"
                ? "歡迎寫低任何意見或者我哋有咩可以改進嘅地方，您嘅意見對我哋嘅進步係好重要㗎～"
                : "We are always happy to get feedback from our valued customers so that we can continue to improve our service!"
            }
          />
        </Stack>
      </Stack>
      <Stack>
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {lang === "tc"
              ? "感謝你的意見，期望您再次光臨！"
              : "Thank you for your feedback, we look forward to seeing you again!"}
          </Alert>
        )}
        {success === false && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {lang === "tc"
              ? "提交失敗，請重新提交或我們回報錯誤"
              : "Ooops, something went wrong. Please try again or report this issue to us."}
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {lang === "tc" ? "請為所有欄位評分" : "Please rate for all fields"}
          </Alert>
        )}
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{ mt: 5 }}
          variant="contained"
          color="warning"
          onClick={handleOnClicked}
        >
          {lang === "tc" ? "發送" : "Send"}
        </Button>
      </Box>
    </>
  );
};

export default FeedbackForm;
