import { Rating, Stack, Typography } from "@mui/material";
import React from "react";

export type Category = "food" | "service" | "ambience" | "cleanliness"

interface Props {
  label: string;
  category: Category;
  onChangeRating: (category: Category, newValue: number | null) => void
}

const RatingWithLabel = ({ label, category, onChangeRating }: Props) => {
  return (
    <Stack>
      <Typography sx={{ fontWeight: 900, color: "#000A66" }}>
        {label}
      </Typography>
      <Rating
        onChange={(event, newValue) => {
          onChangeRating(category, newValue);
        }}
        name={`rating-${category}`}
        defaultValue={0}
        max={10}
        size="small"
      />
    </Stack>
  );
};

export default RatingWithLabel;
