import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const LanguageContext = React.createContext<string>("tc")

export const useLanguageContext = () => {
    return useContext(LanguageContext)
}

const LanguageContextProvider = ({children}: {children: ReactNode}) => {

    const [language, setLanguage] = useState<string>("tc")

    const location = useLocation()

    useEffect(() => {
        if(location.pathname.substring(1,3) === "tc" || location.pathname.substring(1,3) === "en"){
            let lang = location.pathname.substring(1,3)
            setLanguage(lang)
        }
    },[])

  return (
    <LanguageContext.Provider value={language}>
        {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContextProvider